.white-header-portfolio {
    .portfolio-scattered {
        .portfolio-box {
            .portfolio-hover {
                h6 {
                    text-transform: uppercase;
                }
            }
        }
    }
}