$yellow:#208ed8;

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
		-webkit-box-shadow:inset $top $left $blur $color;
		-moz-box-shadow:inset $top $left $blur $color;
		-o-box-shadow:inset $top $left $blur $color;
		box-shadow:inset $top $left $blur $color;
	} @else {
		-webkit-box-shadow: $top $left $blur $color;
		-moz-box-shadow: $top $left $blur $color;
		-o-box-shadow: $top $left $blur $color;
		box-shadow: $top $left $blur $color;
	}
}

@mixin clip-path($clip) {
	-webkit-clip-path: $clip;
	-moz-clip-path: $clip;
	-ms-clip-path: $clip;
	clip-path: $clip;
}

@mixin transition($transition) {
	-moz-transition:    all $transition ease;
	-o-transition:      all $transition ease;
	-webkit-transition: all $transition ease;
	-ms-transition: 	all $transition ease;
	transition:         all $transition ease;
}

.paddding {
	padding-left: 0!important;
	padding-right: 0!important;
}



// Header

.navbar-light .navbar-nav .open > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.open, .navbar-light .navbar-nav .nav-link.active {
	color: #fff!important;
	padding-top: 12px!important;
	padding-bottom: 15px!important;
	padding-left: 20px!important;
	padding-right: 20px!important;
	margin: 0 20px!important;
	@include transition(0.5s);
	background: $yellow!important;
	@include clip-path(polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0));
}
.navbar-light .navbar-nav .nav-link {
	color: #222!important;
	padding-top: 12px!important;
	padding-bottom: 15px!important;
	padding-left: 20px!important;
	padding-right: 20px!important;
	margin: 0 20px!important;
	@include transition(0.5s);
	position: relative!important;
	@include clip-path(polygon(100% 0, 100% 85%, 14% 85%, 0 100%, 0% 60%, 0 0));

	&:focus, &:hover {
		background: $yellow!important;
		color: #fff!important;
		padding-top: 12px!important;
		padding-bottom: 15px!important;
		padding-left: 20px!important;
		padding-right: 20px!important;
		margin: 0 20px;
		position: relative;
	}
}

.bg-faded {
    background-color: #f7f7f7;
  }

.mobile_logo_width {
	display: none;
}

.navbar-nav {
 flex-direction: row !important;
 cursor: pointer;   
}

// form
.heading {
    font-size: 20px;
	color: #424040;
    font-weight: 600;
}

.input-text-box {
    @include border-radius(0!important);
	font-size: 15px!important;
	height: 45px!important;
	letter-spacing: 1px!important;  
}

.form-control:focus {
    box-shadow: none !important;
    border-color: $yellow !important;
}

.btn-sign-in {
    background: #c43421!important;
    @include border-radius(0!important);
	color: #fff!important;
	padding: 10px 50px!important;
    border-radius: 5px !important;
}

.btn-sign-up {
    background: #4287f5!important;
    @include border-radius(0!important);
	color: #fff!important;
	padding: 10px 50px!important;
    border-radius: 5px !important;
}

.blog-form input:focus {
    border-color: $yellow !important;
}

.description-box {
    @include border-radius(0!important);
	height: 150px;
	resize: none;
	padding-top: 20px!important;
	letter-spacing: 1px!important; 
}

.btn-add {
    background: #bf7034 !important;
	@include border-radius(0!important);
	color: #fff!important;
	padding: 10px 50px!important;
}


.react-tag-input {
    height: 45px !important;
}

.react-tag-input:focus {
    border-color: $yellow !important;   
}

.catg-dropdown {
    width: 100%;
    border-radius: 4px;
    height: 45px;
    color: #806a78;
  }
  
  .catg-dropdown:focus {
      outline: none !important;
      border-color: $yellow;
  }
  
  .trending {
      float: left;
      color: #806a78;
      font-size: 18px;
      margin: auto;
  }

//   Blog Section

.blog-heading {
	font-size: 24px;
	color: #222;
    border-bottom: 1px solid #777;
}

.blogs-img {
    height: 200px;
	@include border-radius(5px);
	overflow: hidden;
	position: relative;
	top: 0;
	@include box-shadow(0, 2px, 3px, 1px, rgba(0,0,0,0));
	@include transition(all 0.5s);
}

.blogs-img > img {
    height: 260px;
	min-width: 100%;
}

.hover-blogs-img:hover > .blogs-img {
    top: -7px;
	@include box-shadow(0, 2px, 3px, 1px, rgba(0,0,0,0.3));
}

.title {
    font-size: 17px;
	color: #222!important;
	font-weight: 800;
    font-family: "Poppins";
    text-decoration: none;
	display: block;
}


.category {
    display: inline-block;
    position: relative;
    background-color: #4287f5;
    padding: 4px 8px;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    text-transform: none;
    line-height: 16px;
    font-weight: 700;
    border-radius: 4px;
}

.fa-trash {
	color: #c43421 !important;
}

.fa-edit {
	color: lightsalmon !important;
}

.meta-info {
    font-size: 15px;
	color: #222;
	display: block;
    font-family: "Poppins";
    text-decoration: none;
}

.blog-single-content .meta-info {
	margin-top: 8px;
	border-bottom: 1px solid #777;
	margin-bottom: 15px;
}

.author {
  display: inline-block;
  font-weight: 600;
  font-size: 15px !important;	
}

.short-description {
    font-size: 14px;
	color: #777;
}

.btn-read {
    background: #544e66 !important;
	@include border-radius(0!important);
	color: #fff!important;
	padding: 5px 10px!important;
	float: left;
	margin-top: 10px;
	font-size: 14px;
}

// Detail

.blog-title-box {
    width: 38.2%;
	z-index: 555;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;

    .single & {
		position: relative;
		height: 700px;
		width: 100%;
	}

    .overlay {
        position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
		background: rgba(0,0,0,.4);
    }

    .blog-title {
        z-index: 12;
		position: absolute;
		bottom: 0;
		padding: 30px;

        .single & {
			width: 100%;
			margin: 0 auto;
			text-align: center;
		}

        h2 {
            color: white;
			font-weight: 300;
            .single & {
				font-size: 72px;
			}
        }

        > span {
            display: block;
			color: rgba(255,255,255,.5);
			text-transform: uppercase;
			font-size: 15px;
			letter-spacing: .05em;
			margin-bottom: 10px;
        }
    }
    
}

.blog-single-content {
    z-index: 999;
	background: white;

	p, ul li {
		font-size: 1.05rem;
	}
}

// Home
.spinner {
	width: 3rem;
	height: 3rem;
}

// Tags

.tags > a {
    text-decoration: none;
}
.tag {
	background: #f1f1f1;
	padding: 10px 15px;
	color: #222;
	@include transition(0.5s);
	margin-right: 8px;
	margin-top: 5px;
	margin-bottom: 5px;
	display: inline-block;
}
.tag:hover, .tag:focus {
	background: $yellow;
	color: #fff;
	text-decoration: none;
}

// Most popular

.most-popular-img {
    height: 80px;
	width: 100%;
}

.most-popular-font {
   font-weight: 500; 
}

.most-popular-font-meta {
    font-size: 12px;
	color: #777;
}

// Trending
.trending-img-position {
	position: relative;
	overflow: hidden;
	z-index: 5;
	cursor: pointer;
}

.trending-img-size {
	height: 326px;
	overflow: hidden;
}
.trending-img-size > img {
	height: 340px;
	min-width: 100%;
}

.trending-img-relative {
	position: relative;
}

.trending-img-absolute {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.83) 100%);
	background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0.83) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0.83) 100%);
	z-index: 7;
}

.trending-img-absolute-1 {
	position: absolute;
	width: 100%;
	z-index: 9;
	padding: 10px;
	bottom: 0;
}

.trending-meta-info {
	color: rgba(255,255,255,0.7);
	font-size: 13px;
}

.trending-img-position:hover {
	-webkit-animation: swing1 1s ease;
	animation: swing1 1s ease;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}
 @-webkit-keyframes swing1 {
 15% {
 -webkit-transform: translateX(5px);
 transform: translateX(5px);
}
 30% {
 -webkit-transform: translateX(-5px);
 transform: translateX(-5px);
}
 50% {
 -webkit-transform: translateX(3px);
 transform: translateX(3px);
}
 65% {
 -webkit-transform: translateX(-3px);
 transform: translateX(-3px);
}
 80% {
 -webkit-transform: translateX(2px);
 transform: translateX(2px);
}
 100% {
 -webkit-transform: translateX(0);
 transform: translateX(0);
}
}
@keyframes swing1 {
 15% {
 -webkit-transform: translateX(5px);
 transform: translateX(5px);
}
 30% {
 -webkit-transform: translateX(-5px);
 transform: translateX(-5px);
}
 50% {
 -webkit-transform: translateX(3px);
 transform: translateX(3px);
}
 65% {
 -webkit-transform: translateX(-3px);
 transform: translateX(-3px);
}
 80% {
 -webkit-transform: translateX(2px);
 transform: translateX(2px);
}
 100% {
 -webkit-transform: translateX(0);
 transform: translateX(0);
}
}

// search
.form-inline {
	display: flex;
	align-items: center;
	width: fit-content;
}

.search-btn {
	float: left;
}

// Category
.widget {
	position: relative;
    display: block;
    margin-bottom: 3rem;
}
.widget ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.link-widget li {
    padding: 1.2rem 0.8rem 1.7rem;
    text-transform: capitalize;
    line-height: 1;
    font-weight: 500;
    position: relative;
    margin: 0;
    border-bottom: 1px dashed #dadada;
}

.link-widget li:last-child {
    border-bottom: 0 dashed #dadada;
}

.link-widget li span {
    position: absolute;
    right: 0;
}

// Scroll
.scroll-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	@include transition(.5s);
	cursor: pointer;
	z-index: 1;
	span {
		width: 50px;
		height: 50px;
		display: table;
		background: rgba(0,0,0,.5);
		color: white;
		text-align: center;
		@include border-radius(4px);
	}
	i {
		height: 20px;
		display: table-cell;
		vertical-align: middle;
	}
  }

//   Card
.related-content {
	border-radius: 20px;
	cursor: pointer;
  }

// Comment

.custombox {
	position: relative;
    padding: 3rem 2rem;
    border: 1px dashed #dadada;
	margin-top: 20px;
}

.comments-list p {
    margin-bottom: 1rem
}

.comments-list .media-right,
.comments-list small {
    color: #999 !important;
    font-size: 11px;
    letter-spacing: 2px;
    margin-top: 5px;
    padding-left: 10px;
    text-transform: uppercase;
}

.media {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
		-ms-flex-align: start;
			align-items: flex-start;
  }
  

.comments-list .media {
    padding: 1rem;
    margin-bottom: 15px;
}

.media-body {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 0%;
		-ms-flex: 1 1 0%;
			flex: 1 1 0%;
  }

.media-body .btn-primary {
    padding: 5px 15px !important;
    font-size: 11px !important;
}

.comments-list img {
    max-width: 80px;
    margin-right: 30px;
}
	 

.small-title {
    background: #edeff2 none repeat scroll 0 0;
    font-size: 16px;
	font-weight: 700;
    left: 5%;
    line-height: 1;
    margin: 0;
    padding: 0.6rem 1.5rem;
    position: absolute;
    text-align: center;
    top: -18px;
	color:#ffffff !important;
	background-color: #74a044 !important;
	border-color: #74a044 !important;
}

.user_name {
    font-size: 16px;
    font-weight: 600;
}

// Pagination
.btn_mange_pagging {
	background: #dadada;
	padding: 12px 20px;
	color: #222!important;
	font-weight: 800;
	margin: 0 10px;
	border-radius: 10px;
	@include transition(0.5s);
}

.btn_mange_pagging:hover, .btn_mange_pagging:focus {
	background: #222;
	color: #fff!important;
	font-weight: 800;
}

.btn_pagging {
	background: #dadada!important;
	color: #222;
	padding: 12px 20px!important;
	font-weight: 800;
	margin: 0 10px;
	@include transition(0.5s);
}

.btn_pagging:hover, .btn_pagging:focus {
	background: #222!important;
	color: #fff;
}

.scroll {
	width: auto;
	height: 230px;
	overflow-x: hidden;
	overflow-y: auto;
	text-align:justify;
}